import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from '../../../../bundles/Client/common/actions/userActions';
import SignIn from './SignIn.res.js';

const mapStateToProps = (state, props) => ({
  userData: state.user,
  invalidInvitation: Object.values(props)[0].invalidInvitation,
});

const mapDispatchToProps = dispatch => ({
  setUserData: userData => dispatch(setUserData(userData)),
});

const SignInContainer = connect(mapStateToProps, mapDispatchToProps)(SignIn);

export default ({ context, ...props }) => (
  <ReduxProvider>
    <SignInContainer props={props} context={context} />
  </ReduxProvider>
);
